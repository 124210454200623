import { useEffect, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import QuestionIcon from '@ancon/wildcat-ui/shared/icons/question-fill.svg'
import { useRouter } from 'next/router'
import moment from 'moment'

import HeadingText from '../../../app/components/HeadingText'
import BodyText from '../../../app/components/BodyText'
import GroupOrderForm from '../../forms/GroupOrderForm'
import { NewOrderStep, PreOrderMethod } from '../../types'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import {
  preOrderNewOrderOrderFormatSelector,
  preOrderNewOrderSelectedOrderMethodSelector,
  preOrderNewOrderTimeSelector,
} from '../../store/preOrderSelectors'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import {
  setNewOrderDetails,
  setNewPreOrderEditModeStep,
} from '../../store/preOrderSlice'
import { outletsSelectedOutletListItemUrlSlugSelector } from '../../../outlets/store/outletsSelectors'
import useAppStore from '../../../../store/hooks/useAppStore'
import { OrderFormatSlugMap } from '../../../app/constants'
import isGroupOrderSupportedOrderFormat from '../../utils/isGroupOrderSupportedOrderFormat'

import NewOrderHeader from './NewOrderHeader'
import NewOrderMethodSelectButton from './NewOrderMethodSelectButton'
import NewOrderProceedButton from './NewOrderProceedButton'
import GroupOrderUnSupportedNote from './GroupOrderUnSupportedNote'
import styles from './NewOrderMethodSelection.module.scss'

interface NewOrderMethodSelectionProps {
  inModal?: boolean
  isMobile?: boolean
}

export default function NewOrderMethodSelection({
  inModal = false,
  isMobile = false,
}: NewOrderMethodSelectionProps) {
  const { t } = useTranslation('preOrder')

  const router = useRouter()

  const store = useAppStore()

  const dispatch = useAppDispatch()

  const initialSelectedMethod = useAppSelector(
    preOrderNewOrderSelectedOrderMethodSelector,
  )
  const orderFormat = useAppSelector(preOrderNewOrderOrderFormatSelector)
  const orderTimestamp = useAppSelector(preOrderNewOrderTimeSelector)

  const [selectedMethod, setSelectedMethod] = useState<PreOrderMethod>(
    initialSelectedMethod,
  )

  const isNormalOrder = selectedMethod === PreOrderMethod.Individual
  const isGroupOrder = selectedMethod === PreOrderMethod.Group

  useEffect(() => {
    setSelectedMethod(initialSelectedMethod)
  }, [initialSelectedMethod])

  function handleContinueNormalOrder() {
    const urlSlug = outletsSelectedOutletListItemUrlSlugSelector(
      store.getState(),
    )

    dispatch(
      setNewOrderDetails({
        orderMethod: PreOrderMethod.Individual,
        // Reset group order details
        groupOrderName: null,
        groupOrderSize: null,
      }),
    )
    dispatch(setNewPreOrderEditModeStep(NewOrderStep.None))
    document.body.scrollTo({ top: 0, behavior: 'smooth' })
    // Navigate to outlet page
    router.push({
      pathname: `outlet/${urlSlug}`,
      query: {
        orderFormat: OrderFormatSlugMap[orderFormat as never],
        serviceTime: moment(orderTimestamp).toISOString(),
      },
    })
  }

  return (
    <div className={styles.container}>
      <NewOrderHeader
        className={styles.header}
        inModal={inModal}
        isMobile={isMobile}
        showOutlet
      />
      <div className={styles.orderMethodSelection}>
        <div className={styles.methodSelectionLabel}>
          <HeadingText as="h3" color="heading-1">
            {t('newOrder.normalOrGroupOrder')}
          </HeadingText>
          <QuestionIcon />
        </div>
        <div className={styles.orderMethodOptions}>
          <NewOrderMethodSelectButton
            orderMethod={PreOrderMethod.Individual}
            selected={isNormalOrder}
            onSelect={setSelectedMethod}
          />
          <NewOrderMethodSelectButton
            orderMethod={PreOrderMethod.Group}
            selected={isGroupOrder}
            onSelect={setSelectedMethod}
          />
        </div>
        {isGroupOrder && (
          <BodyText as="p" color="body-2" fontSize="1.2rem">
            {t('newOrder.orderMethodDescription')}
          </BodyText>
        )}
      </div>
      {isGroupOrder &&
        (isGroupOrderSupportedOrderFormat(orderFormat!) ? (
          <GroupOrderForm inModal={inModal} isMobile={isMobile} />
        ) : (
          <GroupOrderUnSupportedNote />
        ))}
      {isNormalOrder && (
        <NewOrderProceedButton onClick={handleContinueNormalOrder} />
      )}
    </div>
  )
}
