import useTranslation from 'next-translate/useTranslation'

import BodyText from '../../../app/components/BodyText'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import { preOrderNewOrderOrderFormatSelector } from '../../store/preOrderSelectors'
import useGetOrderFormatName from '../../../app/hooks/useGetOrderFormatName'

import styles from './GroupOrderUnSupportedNote.module.scss'

export default function GroupOrderUnSupportedNote() {
  const { t } = useTranslation('preOrder')

  const getOrderFormatName = useGetOrderFormatName()

  const orderFormat = useAppSelector(preOrderNewOrderOrderFormatSelector)

  const orderFormatName = getOrderFormatName(orderFormat)

  return (
    <div className={styles.container}>
      <BodyText>
        {t('newOrder.groupOrderUnSupportedNote', {
          orderFormat: orderFormatName,
        })}
      </BodyText>
    </div>
  )
}
